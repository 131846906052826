import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { getDesignTokens } from "theme/theme";
import Header from "components/common/header";
import Footer from "components/common/footer";
import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "Route";
import { BrowserRouter } from "react-router-dom";
import { saveAuth, removeAuth } from "./utils/cache";
import { AppProvider } from "context/appContext";
import NavBar from "components/common/navBar/index";
import "./App.scss";
import jwtDecode from "jwt-decode";
import ModalPopup from "components/modalPopup";
import constants from "./constants/constants.json";

function App() {
  const [currentTheme, setCurrentTheme] = useState(() => {
    let savedTheme = localStorage.getItem("theme");
    if (!savedTheme) {
      localStorage.setItem("theme", "light");
      savedTheme = "light";
    }
    return savedTheme;
  });
  const scrollRef = useRef(null);
  const [isNavBarOpen, setIsNavbarOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isUserActive = useRef(true);
  const logoutOnInactivity = useRef(null);

  const inactivityTimeout = 10 * 60 * 1000; // 10 minutes
  const inactivityTimeoutSignOut = 5 * 60 * 1000; // 5 minutes

  const inactivityTimer = useRef(null);
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const theme = useMemo(
    () => createTheme(getDesignTokens(currentTheme)),
    [currentTheme]
  );

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  function setMode(theme) {
    localStorage.setItem("theme", theme || "light");
    const mode = theme;
    setCurrentTheme(mode);
  }

  async function saveToken() {
    try {
      const access_token = await getAccessTokenSilently();
      const id_token = await getIdTokenClaims();
      await removeAuth();
      await saveAuth(user, access_token, id_token["__raw"]);
      setIsAuthLoaded(true);
      setTokenRefreshTimer(access_token);
    } catch (e) {
      console.log(e);
      console.log(error);
      removeAuth();
    }
  }

  const calculateRefreshTime = (access_token) => {
    const decodedToken = jwtDecode(access_token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    return expirationTime - currentTime - 5 * 60 * 1000; // 5 minutes before expiration
  };

  const refreshToken = async () => {
    const newAccessToken = await getAccessTokenSilently({ ignoreCache: true });
    const id_token = await getIdTokenClaims();
    await saveAuth(user, newAccessToken, id_token["__raw"]);
    setTokenRefreshTimer(newAccessToken);
  };

  const setTokenRefreshTimer = (access_token) => {
    const refreshTime = calculateRefreshTime(access_token);
    if (refreshTime > 0 && isUserActive.current) {
      setTimeout(refreshToken, refreshTime);
    } else if (!isUserActive.current) {
      setOpenModal(true);
      logoutOnInactivity.current = setTimeout(() => {
        logout({ returnTo: window.location.origin });
      }, inactivityTimeoutSignOut);
    }
  };

  const resetInactivityTimer = useCallback(() => {
    isUserActive.current = true;
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(
      () => (isUserActive.current = false),
      inactivityTimeout
    );
  }, [inactivityTimeout]);
  useEffect(() => {
    const activityEvents = ["mousemove", "keypress", "scroll"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );
    inactivityTimer.current = setTimeout(
      () => (isUserActive.current = false),
      inactivityTimeout
    );

    return () => {
      clearTimeout(inactivityTimer.current);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
    };
  }, [resetInactivityTimer, inactivityTimeout]);

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect();
      return;
    }
    saveToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  const setPopupClicked = () => {
    clearTimeout(logoutOnInactivity.current);
    setOpenModal(false);
    isUserActive.current = true;
    refreshToken();
  };

  return isAuthenticated && isAuthLoaded ? (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <div
          className="App"
          ref={scrollRef}
          style={{ backgroundColor: theme.palette.themeColor }}
        >
          <BrowserRouter>
            <Box>
              <Header
                scrollRef={scrollRef}
                isNavbarOpen={isNavBarOpen}
                handleSearchQueryChange={handleSearchQueryChange}
                searchQuery={searchQuery}
              >
                <NavBar
                  setMode={setMode}
                  user={user}
                  onNavBarExpand={() => setIsNavbarOpen(true)}
                  onNavBarCollapse={() => setIsNavbarOpen(false)}
                  logout={logout}
                />
              </Header>
            </Box>

            <AppRoutes isNavbarOpen={isNavBarOpen} />
            <Footer />
            <ModalPopup
              title={constants.sessionTimeoutTitle}
              message={constants.sessionTimeoutMessage}
              buttonText={constants.signOutNow}
              open={openModal}
              buttonText2={constants.sessionTimeoutButtonText}
              btn2Action={setPopupClicked}
              btnAction={() => logout({ returnTo: window.location.origin })}
            />
          </BrowserRouter>
        </div>
      </AppProvider>
    </ThemeProvider>
  ) : (
    <> </>
  );
}

export default App;
